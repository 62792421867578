// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Modal, Button, Form, Row, Col, Alert, Table, Tabs, Tab } from 'react-bootstrap';
// import MappingModal from './MappingForm';
// import Select from 'react-select';
// import { API_URL } from '../config';
// import api from '../services/api';

// const BulkMappingForm = () => {
//     const [location, setLocation] = useState([]);
//     const [billingMonths, setBillingMonths] = useState([]);
//     const [lenders, setLenders] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState('');
//     const [searchParams, setSearchParams] = useState({
//         customer_name: '',
//         los_no: '',
//         loan_amount: '',
//         product: '',
//         lender: [], //changed by Gov
//         location: '',
//     });
//     const [products, setProducts] = useState([]);
//     const [file, setFile] = useState(null);
//     const [leads, setLeads] = useState([]);
//     const [showMappingModal, setShowMappingModal] = useState(false);
//     const [mappingData, setMappingData] = useState({});
//     const [confirmationModal, setConfirmationModal] = useState(false);
//     const [error, setError] = useState('');
//     const [locationSearch, setLocationSearch] = useState('');
//     const [leadsFound, setLeadsFound] = useState([]);
//     const [leadsNotFound, setLeadsNotFound] = useState([]);
//     const [leadsAlreadyMapped, setLeadsAlreadyMapped] = useState([]);
//     const [leadsSummary, setLeadsSummary] = useState({});


//     useEffect(() => {
//         // Fetch billing months, lenders, products, and locations
//         const fetchData = async () => {
//             try {
//                 const billingMonthsResponse = await api.get('/api/billing-months/');
//                 setBillingMonths(billingMonthsResponse.data);
    
//                 const lendersResponse = await api.get('/api/lenders/');
//                 setLenders(lendersResponse.data);
    
//                 const productsResponse = await api.get('/api/products/');
//                 setProducts(productsResponse.data);
    
//                 const locationResponse = await api.get('/api/locations/');
//                 setLocation(locationResponse.data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
    
//         fetchData();
//     }, []);
    

//     const handleInputChange = (e) => {
//         setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
//     };

//     const locationOptions = location.map(loc => ({
//         value: loc.id,
//         label: loc.name
//     }));

//     const lenderOptions = lenders.map(lender => ({    // changed by Govil
//         value: lender.id,
//         label: lender.name
//     }));
    
//     const handleLocationChange = (selectedOption) => {
//         setSearchParams({
//             ...searchParams,
//             location: selectedOption ? selectedOption.value : ''
//         });
//     };
        
    
//     const handleLenderChange = (selectedOptions) => {
//         const selectedLenderIds = selectedOptions?.map((option) => Number(option.value)) || [];  // Convert to number and ensure fallback
//         setSearchParams((prevParams) => ({
//             ...prevParams,
//             lender: selectedLenderIds,  // Store lenders as an array of numbers
//         }));
//     };    
        
    
//     // const handleLocationChange = (selectedOption) => {
//     //     setSearchParams({
//     //         ...searchParams,
//     //         location: selectedOption ? selectedOption.value : ''
//     //     });
//     // };
     
//     // const handleLenderChange = (selectedOptions) => {
//     //     setSearchParams({
//     //         ...searchParams,
//     //         lender: selectedOptions ? selectedOptions.map(option => option.value) : [] 
//     //     });
//     // };

     

//     const handleFileUpload = async (e) => {
//         const file = e.target.files[0];
//         setFile(file);
    
//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append('billing_month', selectedMonth);
//         //formData.append('lender[]', searchParams.lender);
//         // console.log("Lender Value:", searchParams.lender.join(", "));

//         formData.append('product', searchParams.product);
//         formData.append('location', searchParams.location);
//         console.log(formData);
//         // searchParams.lender.forEach(lender => {
//         //     formData.append('lender[]', lender);
//         //   });
//         searchParams.lender.forEach(lenderId => {
//             formData.append('lender[]', lenderId);  // Use 'lender[]' to send multiple values
//         });        
        
//         try {

            
//             const response = await api.post('/api/bulk-mapping/validate_excel/', formData);
    
//             setLeadsFound(response.data.leads_found);
//             setLeadsNotFound(response.data.leads_not_found);
//             setLeadsAlreadyMapped(response.data.leads_already_mapped);
//             setLeadsSummary({
//                 total: response.data.total_leads,
//                 found: response.data.leads_found_count,
//                 notFound: response.data.leads_not_found_count,
//                 alreadyMapped: response.data.leads_already_mapped_count
//             });
//         } catch (error) {
//             setError(error.response?.data?.error || 'An error occurred while processing the file');
//         }
//     };
    





// // *****using axios****
//     // const handleFileUpload = async (e) => {
//     //     const file = e.target.files[0];
//     //     setFile(file);

//     //     const formData = new FormData();
//     //     formData.append('file', file);
//     //     formData.append('billing_month', selectedMonth);
//     // formData.append('lender', searchParams.lender);
//     // formData.append('product', searchParams.product);
//     // formData.append('location', searchParams.location);

//     //     try {
//     //         const response = await axios.post(`${API_URL}api/bulk-mapping/validate_excel/`,
//     //              formData);  
//     //         setLeadsFound(response.data.leads_found);
//     //         setLeadsNotFound(response.data.leads_not_found);
//     //         setLeadsAlreadyMapped(response.data.leads_already_mapped);
//     //         setLeadsSummary({
//     //             total: response.data.total_leads,
//     //             found: response.data.leads_found_count,
//     //             notFound: response.data.leads_not_found_count,
//     //             alreadyMapped: response.data.leads_already_mapped_count
//     //         });
//     //     } catch (error) {
//     //         setError(error.response?.data?.error || 'An error occurred while processing the file');
//     //     }
//     // };

    
    
    
    
    
//     const handleSubmit = () => {
//         if (leadsFound.length > 0) {
//             setShowMappingModal(true);
//         } else {
//             setError('No leads to map');
//         }
//     };

//     const handleMappingSubmit = (data) => {
//         setMappingData(data);
//         setShowMappingModal(false);
//         setConfirmationModal(true);
//     };
//     const handleConfirmMapping = async () => {
//         try {
//             const response = await api.post('/api/bulk-mapping/bulk_map/', {
//                 lead_ids: leadsFound.map(lead => lead.id),
//                 mapping_data: mappingData
//             });
//             alert(response.data.message);
//             setConfirmationModal(false);
            
//             // Reset form and state
//             setSelectedMonth('');
//             setSearchParams({
//                 customer_name: '',
//                 los_no: '',
//                 loan_amount: '',
//                 product: '',
//                 lender: '',
//                 location: '',
//             });
//             setFile(null);
//             setLeads([]);
//             setLeadsFound([]);
//             setLeadsNotFound([]);
//             setLeadsAlreadyMapped([]);
//             setLeadsSummary({});
//         } catch (error) {
//             setError(error.response?.data?.error || 'An error occurred while mapping leads');
//         }
//     };
    
    
    
    
    
    
    
    
    
    
//     // **direct call using  axios**
//     // const handleConfirmMapping = async () => {
//     //     try {
//     //         const response = await axios.post(`${API_URL}api/bulk-mapping/bulk_map/`, {
//     //             lead_ids: leadsFound.map(lead => lead.id),
//     //             mapping_data: mappingData
//     //         });
//     //         alert(response.data.message);
//     //         setConfirmationModal(false);
//     //         // Reset form and state
//     //         setSelectedMonth('');
//     //         setSearchParams({
//     //             customer_name: '',
//     //             los_no: '',
//     //             loan_amount: '',
//     //             product: '',
//     //             lender: '',
//     //             location: '',
//     //         });
//     //         setFile(null);
//     //         setLeads([]);
//     //         setLeadsFound([]);
//     //         setLeadsNotFound([]);
//     //         setLeadsAlreadyMapped([]);
//     //         setLeadsSummary({});
//     //     } catch (error) {
//     //         setError(error.response?.data?.error || 'An error occurred while mapping leads');
//     //     }
//     // };

    
    
//     return (
//         <div>
//             <h2>Payout Bulk Mapping</h2>
//             {error && <Alert variant="danger">{error}</Alert>}
//             <Form>
//                 <Form.Group>
//                     <Form.Label><span style={{ color: 'red',fontSize: '1.1em' }}>*</span> Billing Month</Form.Label> {/*Changes done by sumedh */}
//                     <Form.Control
//                         as="select"
//                         value={selectedMonth}
//                         onChange={(e) => setSelectedMonth(e.target.value)}
//                     >
//                         <option value="">Select Billing Month</option>
//                         {billingMonths.map(month => (
//                             <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
//                         ))}
//                     </Form.Control>
//                 </Form.Group>
//                 <Form.Group>
//                     <Form.Label>Location</Form.Label>
//                     <Select
//                         options={locationOptions}
//                         value={locationOptions.find(opt => opt.value === searchParams.location)}
//                         onChange={handleLocationChange}
//                         isDisabled={!selectedMonth}
//                         isClearable
//                         placeholder="Search or select location..."
//                         noOptionsMessage={() => "No locations found"}
//                     />
//                 </Form.Group>
//                 <Row className="mt-3">
//                     <Col md={3}>
//                         <Form.Group>
//                             <Form.Label>Product</Form.Label>
//                             <Form.Control
//                                 as="select"
//                                 name="product"
//                                 value={searchParams.product}
//                                 onChange={handleInputChange}
//                                 disabled={!selectedMonth}
//                             >
//                                 <option value="">Select Product</option>
//                                 {products.map(product => (
//                                     <option key={product.id} value={product.id}>{product.product_code}</option>
//                                 ))}
//                             </Form.Control>
//                         </Form.Group>
//                     </Col>
                                
//                     <Col md={3}>
//             {/* <Form.Group>
//                 <Form.Label>Lender</Form.Label>
//                 <Select
//                     isMulti
//                     name="lender"
//                     value={searchParams.lender.map((selectedId) => ({
//                         value: selectedId,
//                         label: lenders.find(lender => lender.id === selectedId)?.name,  // Show the lender name for the label
//                     }))}
//                     options={lenders.map((lender) => ({
//                         value: lender.id, // Use lender ID as the value
//                         label: lender.name, // Display lender name as the label
//                     }))}
//                     onChange={(selectedOptions) => {
//                         const selectedValues = selectedOptions?.map((option) => option.value) || [];
//                         setSearchParams((prevParams) => ({
//                             ...prevParams,
//                             lender: selectedValues,
//                         }));
//                         console.log(selectedValues);
//                                     // Print lender names
//                         // const selectedLenderNames = selectedValues.map((id) => 
//                         //     lenders.find((lender) => lender.id === id)?.name
//                         // );
//                         // console.log("Selected Lender Names:", selectedLenderNames); // Print lender names
                    
//                     }}
//                     isDisabled={!selectedMonth}
//                     placeholder="Select Lenders"
//                 />
//             </Form.Group> */}


//     {/* <Form.Group>
//         <Form.Label>Lender</Form.Label>
//         <Select
//             isMulti
//             name="lender"
//             value={searchParams.lender.map((selectedId) => ({
//                 value: selectedId,
//                 label: lenders.find(lender => lender.id === selectedId)?.name,  // Show the lender name for the label
//             }))}
//             options={lenders.map((lender) => ({
//                 value: lender.id, // Use lender ID as the value
//                 label: lender.name, // Display lender name as the label
//             }))}
//             onChange={(selectedOptions) => {
//                 const selectedValues = selectedOptions?.map((option) => option.value) || [];
//                 setSearchParams((prevParams) => ({
//                     ...prevParams,
//                     lender: selectedValues,
//                 }));
//                 console.log(selectedValues); // Print selected lender IDs

//                 // Optionally, print selected lender names
//                 const selectedLenderNames = selectedValues.map((id) => 
//                     lenders.find((lender) => lender.id === id)?.name
//                 );
//                 console.log("Selected Lender Names:", selectedLenderNames); // Print lender names
//             }}
//             isDisabled={!selectedMonth}
//             placeholder="Select Lenders"
//         />
//     </Form.Group> */}


// <Form.Group>
//                             <Form.Label>Lender</Form.Label>
//                             <Form.Control
//                                 as="select"
//                                 name="lender"
//                                 value={searchParams.lender}
//                                 onChange={handleInputChange}
//                                 disabled={!selectedMonth}
//                             >
//                                 <option value="">Select Lender</option>
//                                 {lenders.map(lender => (
//                                     <option key={lender.id} value={lender.id}>{lender.name}</option>
//                                 ))}
//                             </Form.Control>
//                         </Form.Group>



//                     {/* <Form.Group>
//                             <Form.Label>Lender</Form.Label>
//                             <Select
//                                 isMulti
//                                 options={lenderOptions}
//                                 value={lenderOptions.filter(option => searchParams.lender.includes(option.value))}    //change by govil
//                                 onChange={handleLenderChange}
//                                 isDisabled={!selectedMonth}
//                                 placeholder="Select lenders..."
//                             />
//                     </Form.Group>
//   */}
//                     </Col>  
                
//                 </Row>
//                 <Form.Group>
//                     <Form.Label><span style={{ color: 'red',fontSize: '1.1em' }}>*</span> Upload Bulk Mapping File</Form.Label> {/*Changes done by sumedh */}
//                     <Form.Control type="file" onChange={handleFileUpload} />
//                 </Form.Group>
//             </Form>
//             {leadsSummary.total > 0 && (
//                 <div className="mt-4">
//                     <h3>Lead Summary</h3>
//                     <p>Total Leads: {leadsSummary.total}</p>
//                     <p>Leads Found: {leadsSummary.found}</p>
//                     <p>Leads Not Found: {leadsSummary.notFound}</p>
//                     <p>Leads Already Mapped: {leadsSummary.alreadyMapped}</p>
//                 </div>
//             )}
//           {(leadsFound.length > 0 || leadsNotFound.length > 0 || leadsAlreadyMapped.length > 0) && (
//                 <Tabs defaultActiveKey="found" className="mb-3">
//                     <Tab eventKey="found" title={`Leads Found (${leadsFound.length})`}>
//                         <Table striped bordered hover>
//                             <thead>
//                                 <tr>
//                                     <th>ID</th>
//                                     <th>Customer Name</th>
//                                     <th>LOS No</th>
//                                     <th>Excel Customer Name</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {leadsFound.map(lead => (
//                                     <tr key={lead.id}>
//                                         <td>{lead.id}</td>
//                                         <td>{lead.customer_name}</td>
//                                         <td>{lead.loan_no}</td>
//                                         <td>{lead.excel_customer_name}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </Table>
//                     </Tab>
//                     <Tab eventKey="notFound" title={`Leads Not Found (${leadsNotFound.length})`}>
//                         <Table striped bordered hover>
//                             <thead>
//                                 <tr>
//                                     <th>Customer Name</th>
//                                     <th>LOS No</th>
//                                     <th>Reason</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {leadsNotFound.map((lead, index) => (
//                                     <tr key={index}>
//                                         <td>{lead.customer_name}</td>
//                                         <td>{lead.loan_no}</td>
//                                         <td>{lead.reason}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </Table>
//                     </Tab>
//                     <Tab eventKey="alreadyMapped" title={`Leads Already Mapped (${leadsAlreadyMapped.length})`}>
//                         <Table striped bordered hover>
//                             <thead>
//                                 <tr>
//                                     <th>ID</th>
//                                     <th>Customer Name</th>
//                                     <th>LOS No</th>
//                                     <th>Mapped By</th>
//                                     <th>Mapped At</th>
//                                     <th>Source</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {leadsAlreadyMapped.map(lead => (
//                                     <tr key={lead.id}>
//                                         <td>{lead.id}</td>
//                                         <td>{lead.customer_name}</td>
//                                         <td>{lead.loan_no}</td>
//                                         <td>{lead.mapped_by}</td>
//                                         <td>{new Date(lead.mapped_at).toLocaleString()}</td>
//                                         <td>{lead.source}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </Table>
//                     </Tab>
//                 </Tabs>
//             )}

//             {leadsFound.length > 0 && (
//                 <Button onClick={handleSubmit} className="mt-3">Map All Found Leads</Button>
//             )}

//             {showMappingModal && (
//                 <MappingModal
//                     show={showMappingModal}
//                     onClose={() => setShowMappingModal(false)}
//                     onMap={handleMappingSubmit}
//                 />
//             )}

// <Modal show={confirmationModal} onHide={() => setConfirmationModal(false)}>
//             <Modal.Header closeButton>
//                 <Modal.Title>Confirm Bulk Mapping</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <p>{`${leadsFound.length} leads will be mapped with the following details. Proceed?`}</p>
                
//                 {/* Table to display mapping details */}
//                 <Table bordered hover>
//                     <thead>
//                         <tr>
//                             <th>#</th>
//                             <th>Field Name</th>
//                             <th>Mapped Value</th>
//                         </tr>
//                     </thead>
//                     <tbody>
                    
//                         {Object.keys(mappingData).map((key, index) => (
//                             <tr key={index}>
//                                 <td>{index + 1}</td>
//                                 <td>{key}</td>
//                                 <td>{mappingData[key]}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </Table>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={() => setConfirmationModal(false)}>
//                     Cancel
//                 </Button>
//                 <Button variant="primary" onClick={handleConfirmMapping}>
//                     Confirm Mapping
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//         </div>
//     );
// };

// export default BulkMappingForm;














import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Row, Col, Alert, Table, Tabs, Tab } from 'react-bootstrap';
import MappingModal from './MappingForm';
import Select from 'react-select';
import { API_URL } from '../config';
import api from '../services/api';

const BulkMappingForm = () => {
    const [location, setLocation] = useState([]);
    const [billingMonths, setBillingMonths] = useState([]);
    const [lenders, setLenders] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [searchParams, setSearchParams] = useState({
        customer_name: '',
        los_no: '',
        loan_amount: '',
        product: '',
        lender: '',
        location: '',
    });
    const [products, setProducts] = useState([]);
    const [file, setFile] = useState(null);
    const [leads, setLeads] = useState([]);
    const [showMappingModal, setShowMappingModal] = useState(false);
    const [mappingData, setMappingData] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [error, setError] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [leadsFound, setLeadsFound] = useState([]);
    const [leadsNotFound, setLeadsNotFound] = useState([]);
    const [leadsAlreadyMapped, setLeadsAlreadyMapped] = useState([]);
    const [leadsSummary, setLeadsSummary] = useState({});


    useEffect(() => {
        // Fetch billing months, lenders, products, and locations
        const fetchData = async () => {
            try {
                const billingMonthsResponse = await api.get('/api/billing-months/');
                setBillingMonths(billingMonthsResponse.data);
    
                const lendersResponse = await api.get('/api/lenders/');
                setLenders(lendersResponse.data);
    
                const productsResponse = await api.get('/api/products/');
                setProducts(productsResponse.data);
    
                const locationResponse = await api.get('/api/locations/');
                setLocation(locationResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    const handleInputChange = (e) => {
        setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    };

    const locationOptions = location.map(loc => ({
        value: loc.id,
        label: loc.name
    }));

    const handleLocationChange = (selectedOption) => {
        setSearchParams({
            ...searchParams,
            location: selectedOption ? selectedOption.value : ''
        });
    };
    
    const handleLenderChange = (selectedOptions) => {
        setSearchParams({
            ...searchParams,
            lender: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };
    
    // Create options for lenders
    const lenderOptions = lenders.map(lender => ({
        value: lender.id,
        label: lender.name
    }));
    


    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setFile(file);
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('billing_month', selectedMonth);
        formData.append('lender', searchParams.lender);
        formData.append('product', searchParams.product);
        formData.append('location', searchParams.location);

        // Append multiple lenders
        //  searchParams.lender.forEach(lender => {
        // formData.append('lender[]', lender);
        //  });
    
        try {
            const response = await api.post('/api/bulk-mapping/validate_excel/', formData);
            setLeadsFound(response.data.leads_found);
            setLeadsNotFound(response.data.leads_not_found);
            setLeadsAlreadyMapped(response.data.leads_already_mapped);
            setLeadsSummary({
                total: response.data.total_leads,
                found: response.data.leads_found_count,
                notFound: response.data.leads_not_found_count,
                alreadyMapped: response.data.leads_already_mapped_count
            });
        } catch (error) {
            setError(error.response?.data?.error || 'An error occurred while processing the file');
        }
    };
    
// *****using axios****
    // const handleFileUpload = async (e) => {
    //     const file = e.target.files[0];
    //     setFile(file);

    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('billing_month', selectedMonth);
    // formData.append('lender', searchParams.lender);
    // formData.append('product', searchParams.product);
    // formData.append('location', searchParams.location);

    //     try {
    //         const response = await axios.post(`${API_URL}api/bulk-mapping/validate_excel/`,
    //              formData);  
    //         setLeadsFound(response.data.leads_found);
    //         setLeadsNotFound(response.data.leads_not_found);
    //         setLeadsAlreadyMapped(response.data.leads_already_mapped);
    //         setLeadsSummary({
    //             total: response.data.total_leads,
    //             found: response.data.leads_found_count,
    //             notFound: response.data.leads_not_found_count,
    //             alreadyMapped: response.data.leads_already_mapped_count
    //         });
    //     } catch (error) {
    //         setError(error.response?.data?.error || 'An error occurred while processing the file');
    //     }
    // };

    const handleSubmit = () => {
        if (leadsFound.length > 0) {
            setShowMappingModal(true);
        } else {
            setError('No leads to map');
        }
    };

    const handleMappingSubmit = (data) => {
        setMappingData(data);
        setShowMappingModal(false);
        setConfirmationModal(true);
    };
    const handleConfirmMapping = async () => {
        try {
            const response = await api.post('/api/bulk-mapping/bulk_map/', {
                lead_ids: leadsFound.map(lead => lead.id),
                mapping_data: mappingData
            });
            alert(response.data.message);
            setConfirmationModal(false);
            
            // Reset form and state
            setSelectedMonth('');
            setSearchParams({
                customer_name: '',
                los_no: '',
                loan_amount: '',
                product: '',
                lender: '',  
                location: '',
            });
            setFile(null);
            setLeads([]);
            setLeadsFound([]);
            setLeadsNotFound([]);
            setLeadsAlreadyMapped([]);
            setLeadsSummary({});
        } catch (error) {
            setError(error.response?.data?.error || 'An error occurred while mapping leads');
        }
    };
    
    // **direct call using  axios**
    // const handleConfirmMapping = async () => {
    //     try {
    //         const response = await axios.post(`${API_URL}api/bulk-mapping/bulk_map/`, {
    //             lead_ids: leadsFound.map(lead => lead.id),
    //             mapping_data: mappingData
    //         });
    //         alert(response.data.message);
    //         setConfirmationModal(false);
    //         // Reset form and state
    //         setSelectedMonth('');
    //         setSearchParams({
    //             customer_name: '',
    //             los_no: '',
    //             loan_amount: '',
    //             product: '',
    //             lender: '',
    //             location: '',
    //         });
    //         setFile(null);
    //         setLeads([]);
    //         setLeadsFound([]);
    //         setLeadsNotFound([]);
    //         setLeadsAlreadyMapped([]);
    //         setLeadsSummary({});
    //     } catch (error) {
    //         setError(error.response?.data?.error || 'An error occurred while mapping leads');
    //     }
    // };

    return (
        <div>
            <h2>Payout Bulk Mapping</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group>
                    <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> Billing Month</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                        <option value="">Select Billing Month</option>
                        {billingMonths.map(month => (
                            <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Select
                        options={locationOptions}
                        value={locationOptions.find(opt => opt.value === searchParams.location)}
                        onChange={handleLocationChange}
                        isDisabled={!selectedMonth}
                        isClearable
                        placeholder="Search or select location..."
                        noOptionsMessage={() => "No locations found"}
                    />
                </Form.Group>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Product</Form.Label>
                            <Form.Control
                                as="select"
                                name="product"
                                value={searchParams.product}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            >
                                <option value="">Select Product</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>{product.product_code}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Lender</Form.Label>
                            <Form.Control
                                as="select"
                                name="lender"
                                value={searchParams.lender}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            >
                                <option value="">Select Lender</option>
                                {lenders.map(lender => (
                                    <option key={lender.id} value={lender.id}>{lender.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        {/* <Form.Group>
                        <Form.Label>Lender</Form.Label>
                        <Select
                        isMulti
                        options={lenderOptions}
                        value={lenderOptions.filter(option => searchParams.lender.includes(option.value))}
                        onChange={handleLenderChange}
                        isDisabled={!selectedMonth}
                        placeholder="Select Lenders"
                         />
                        </Form.Group> */}
                    </Col>
                </Row>
                <Form.Group>
                    <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> Upload Bulk Mapping File</Form.Label>
                    <Form.Control type="file" onChange={handleFileUpload} />
                </Form.Group>
            </Form>
            {leadsSummary.total > 0 && (
                <div className="mt-4">
                    <h3>Lead Summary</h3>
                    <p>Total Leads: {leadsSummary.total}</p>
                    <p>Leads Found: {leadsSummary.found}</p>
                    <p>Leads Not Found: {leadsSummary.notFound}</p>
                    <p>Leads Already Mapped: {leadsSummary.alreadyMapped}</p>
                </div>
            )}
          {(leadsFound.length > 0 || leadsNotFound.length > 0 || leadsAlreadyMapped.length > 0) && (
                <Tabs defaultActiveKey="found" className="mb-3">
                    <Tab eventKey="found" title={`Leads Found (${leadsFound.length})`}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Customer Name</th>
                                    <th>LOS No</th>
                                    <th>Excel Customer Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadsFound.map(lead => (
                                    <tr key={lead.id}>
                                        <td>{lead.id}</td>
                                        <td>{lead.customer_name}</td>
                                        <td>{lead.loan_no}</td>
                                        <td>{lead.excel_customer_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="notFound" title={`Leads Not Found (${leadsNotFound.length})`}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>LOS No</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadsNotFound.map((lead, index) => (
                                    <tr key={index}>
                                        <td>{lead.customer_name}</td>
                                        <td>{lead.loan_no}</td>
                                        <td>{lead.reason}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="alreadyMapped" title={`Leads Already Mapped (${leadsAlreadyMapped.length})`}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Customer Name</th>
                                    <th>LOS No</th>
                                    <th>Mapped By</th>
                                    <th>Mapped At</th>
                                    <th>Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadsAlreadyMapped.map(lead => (
                                    <tr key={lead.id}>
                                        <td>{lead.id}</td>
                                        <td>{lead.customer_name}</td>
                                        <td>{lead.loan_no}</td>
                                        <td>{lead.mapped_by}</td>
                                        <td>{new Date(lead.mapped_at).toLocaleString()}</td>
                                        <td>{lead.source}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            )}

            {leadsFound.length > 0 && (
                <Button onClick={handleSubmit} className="mt-3">Map All Found Leads</Button>
            )}

            {showMappingModal && (
                <MappingModal
                    show={showMappingModal}
                    onClose={() => setShowMappingModal(false)}
                    onMap={handleMappingSubmit}
                />
            )}

<Modal show={confirmationModal} onHide={() => setConfirmationModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Bulk Mapping</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{`${leadsFound.length} leads will be mapped with the following details. Proceed?`}</p>
                
                {/* Table to display mapping details */}
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Field Name</th>
                            <th>Mapped Value</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        {Object.keys(mappingData).map((key, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{key}</td>
                                <td>{mappingData[key]}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setConfirmationModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirmMapping}>
                    Confirm Mapping
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
    );
};

export default BulkMappingForm;
