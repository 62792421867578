import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Card, Table, Alert } from 'react-bootstrap';
import { API_URL } from '../config';
import api from '../services/api';

const VerifyFile = () => {
    const [billingMonths, setBillingMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [lenders, setLenders] = useState([]);
    const [selectedLender, setSelectedLender] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [summary, setSummary] = useState(null);
    const [rejectionReason, setRejectionReason] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    const userType = user ? user.user_type : null; 

    useEffect(() => {
        // Fetch billing months, lenders, and products
        const fetchData = async () => {
            try {
                const billingMonthsResponse = await api.get('/api/billing-months/');
                setBillingMonths(billingMonthsResponse.data);
    
                const lendersResponse = await api.get('/api/lenders/');
                setLenders(lendersResponse.data);
    
                const productsResponse = await api.get('/api/products/');
                setProducts(productsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    // const handleSubmit = () => {
    //     setErrorMessage('');
    //     axios.get(`${API_URL}api/files-to-verify/`, {
    //         params: {
    //             billing_month: selectedMonth,
    //             lender: selectedLender,
    //             product: selectedProduct
    //         },
    //         headers: {
    //           'Authorization': `Bearer ${token}`,   
    //           'X-User-Type': userType  
    //         }
    //     })
    //         .then(response => setFiles(response.data))
    //         .catch(error => {
    //             if (error.response && error.response.data.error) {
    //                 setErrorMessage(error.response.data.error);
    //             } else {
    //                 console.error('Error fetching files:', error);
    //             }
    //         });
    // };
    const handleSubmit = () => {
        setErrorMessage('');
        api.get('/api/files-to-verify/', {
            params: {
                billing_month: selectedMonth,
                lender: selectedLender,
                product: selectedProduct
            }
        })
        .then(response => setFiles(response.data))
        .catch(error => {
            if (error.response && error.response.status === 403) {
                console.log('User denied access');
                
                // alert("You don't have access to this module.");
            } else if (error.response && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error('Error fetching files:', error);
            }
        });
    };
    

    const handleFileSelect = (fileId) => {
        setSelectedFile(fileId);
        api.get(`/api/file-summary/${fileId}/`)
            .then(response => setSummary(response.data))
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    // alert("You don't have access to this module.");
                } else {
                    console.error('Error fetching file summary:', error);
                }
            });
    };
    


    const handleVerify = () => {
        setErrorMessage('');
        api.post(`/api/verify-file/${selectedFile}/`)
            .then(response => {
                alert('File verified successfully');
                resetUI();  // Reset the UI
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    alert("You don't have access to this module.");
                } else if (error.response && error.response.data.error) {
                    setErrorMessage(error.response.data.error);
                } else {
                    console.error('Error verifying file:', error);
                }
            });
    };
    

    const handleReject = async () => {
        if (!rejectionReason) {
            alert('Please provide a reason for rejection');
            return;
        }
    
        setErrorMessage('');
        try {
            await api.post(`/api/reject-file/${selectedFile}/`, { reason: rejectionReason });
            alert('File rejected successfully');
            resetUI();  
        } catch (error) {
            if (error.response && error.response.status === 403) {
                // alert("You don't have access to this module.");
            } else if (error.response && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error('Error rejecting file:', error);
            }
        }
    };
    

    const resetUI = () => {
        setFiles([]);
        setSelectedFile(null);
        setSummary(null);
        setRejectionReason('');
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Verify Monthly File</Card.Title>
                <Form>
                    <Form.Group className='mt-4'>
                    <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> Select Billing Month </Form.Label> {/* changes done by sumedh*/}
                        <Form.Control as="select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                            <option value=""> Select Billing Month</option>
                            {billingMonths.map(month => (
                                <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                    <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> Select Lender </Form.Label> {/* changes done by sumedh*/}
                        <Form.Control as="select" value={selectedLender} onChange={(e) => setSelectedLender(e.target.value)}>
                            <option value="">Select Lender</option>
                            {lenders.map(lender => (
                                <option key={lender.id} value={lender.id}>{lender.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                    <Form.Label>Select Product</Form.Label>
                        <Form.Control as="select" value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                            <option value="">Select Product</option>
                            {products.map(product => (
                                <option key={product.id} value={product.id}>{product.product_name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button className='mt-4' variant="primary" onClick={handleSubmit}>Submit</Button>
                </Form>

                {errorMessage && (
                    <Alert variant="danger" className="mt-4">
                        {errorMessage}
                    </Alert>
                )}

                {files.length > 0 && (
                    <Form.Group className="mt-3">
                        <Form.Label>Select File to Verify</Form.Label>
                        <Form.Control as="select" onChange={(e) => handleFileSelect(e.target.value)}>
                            <option value="">Select File</option>
                            {files.map(file => (
                                <option key={file.id} value={file.id}>{file.file_name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                )}

                {summary && (
                    <Card className="mt-3">
                        <Card.Body>
                            <Card.Title>Summary</Card.Title>
                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <th>Lender Name</th>
                                        <td>{summary.lender_name}</td>
                                    </tr>
                                    <tr>
                                        <th>No. of Records in File</th>
                                        <td>{summary.record_count}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Gross Loan Amount</th>
                                        <td>₹ {summary.total_gross_loan}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Net Loan Amount</th>
                                        <td>₹ {summary.total_net_loan}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Payout Amount</th>
                                        <td>₹ {summary.total_external_payout_amt}</td> {/* changes done by sumedh */}
                                    </tr>
                                </tbody>
                            </Table>
                            <Button variant="success" onClick={handleVerify} className="mr-2">Verify</Button>
                            {selectedFile && (
                                <Form.Group>
                                    <Form.Control
                                        className='mt-4'
                                        as="textarea"
                                        value={rejectionReason}
                                        onChange={(e) => setRejectionReason(e.target.value)}
                                        placeholder="Reason for rejection"
                                    />
                                    <Button variant="danger" onClick={handleReject} className="mt-4">Reject File</Button>
                                </Form.Group>
                            )}
                        </Card.Body>
                    </Card>
                )}
            </Card.Body>
        </Card>
    );
};

export default VerifyFile;
